import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { AppLayout } from "@cloudscape-design/components";
import Sidebar from "./components/Sidebar";
import Docs from "./components/Docs";
import APIReference from "./components/APIReference";
import TopNavigation from "@cloudscape-design/components/top-navigation";
import SidebarProvider from "./context/SidebarContext";


const App: React.FC = () => {
  useEffect(() => {
    const rootElement = document.getElementById("root");

    if (rootElement) {
      const firstChild = rootElement.firstElementChild;

      if (firstChild) {
        setTimeout(() => {
          firstChild.style.position = "sticky";
          firstChild.style.top = "0";
          firstChild.style.zIndex = "1";
        }, 250);
      }
    }
  }, []);

  return (
   <SidebarProvider>    
    <Router
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <TopNavigation
        identity={{
          title: "FinalEditAI",
          href: "/",
          logo: {
            src: "/logo.png",
            alt: "FinalEditAI Logo",
          },
        }}
        utilities={[
          { type: "button", text: "Docs", href: "/docs" },
          { type: "button", text: "API Reference", href: "/api-reference" },
          {
            type: "button",
            text: "Sign Out",
            onClick: () => alert("Signed Out"),
          },
        ]}
      />

      <AppLayout
        navigation={<Sidebar />}
        content={
          <Routes>
            <Route path="/" element={<Navigate replace to="/docs" />} />
            <Route path="/docs" element={<Docs />} />
            <Route path="/api-reference" element={<APIReference />} />
          </Routes>
        }
        toolsHide={true}
      />
    </Router>
   </SidebarProvider>    
  );
};

export default App;
