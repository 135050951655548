import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm"; // GitHub-Flavored Markdown plugin
import remarkSlug from "remark-slug"; // Plugin to generate IDs for headings
import "github-markdown-css"; // GitHub-style markdown
import "prismjs/themes/prism-tomorrow.css"; // Syntax highlighting
import Prism from "prismjs"; // Prism core
import "prismjs/components/prism-javascript"; // Add language support
import "prismjs/components/prism-python"; // Add language support
import "prismjs/components/prism-http"; // HTTP request formatting
import "prismjs/components/prism-json"; // Add JSON support
import { useSidebar } from "../context/SidebarContext";

const Docs = () => {
  const [content, setContent] = useState("");
  const { setNavigationState } = useSidebar(); // Access SidebarContext

  useEffect(() => {
    let isMounted = true; // Prevent duplicate updates on re-renders
    fetch("/content/docs.md")
      .then((res) => {
        if (!res.ok) {
          throw new Error(`Failed to fetch markdown: ${res.statusText}`);
        }
        return res.text();
      })
      .then((text) => {
        if (isMounted) {
          setContent(text);

          // Dynamically parse navigation items from Markdown headings
          const parsedItems = parseMarkdownToNavigation(text);

          // Set the title and items for the sidebar
          setNavigationState({
            title: "Docs",
            items: parsedItems,
          });
        }
      })
      .catch((err) => console.error(err.message));

    return () => {
      isMounted = false; // Cleanup on component unmount
    };
  }, [setNavigationState]);

  useEffect(() => {
    // Apply syntax highlighting after content updates
    Prism.highlightAll();
  }, [content]);

  return (
    <div>
      <h1>Docs</h1>
      <div className="markdown-body">
        <ReactMarkdown
          remarkPlugins={[remarkGfm, remarkSlug]}
          components={{
            a: ({ node, ...props }) => (
              <a {...props} target="_blank" rel="noopener noreferrer">
                {props.children}
              </a>
            ),
          }}
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

const parseMarkdownToNavigation = (markdown) => {
  const lines = markdown.split("\n");
  const navigationItems = [];
  let lastSection = null;
  let lastSubSection = null;

  lines.forEach((line) => {
    const headingMatch = line.match(/^(#{1,6})\s+(.*)/); // Match Markdown headings
    if (headingMatch) {
      const [, hashes, text] = headingMatch;
      const id = text.toLowerCase().replace(/\s+/g, "-").replace(/[^\w-]/g, ""); // Clean ID
      const href = `/docs#${id}`; // Add the full path to `href`

      if (hashes.length === 1) {
        lastSection = { type: "section", text, items: [] };
        navigationItems.push(lastSection);
        lastSubSection = null;
      } else if (hashes.length === 2 && lastSection) {
        lastSubSection = { type: "section", text, items: [] };
        lastSection.items.push(lastSubSection);
      } else if (hashes.length === 3 && lastSubSection) {
        lastSubSection.items.push({ type: "link", text, href });
      }
    }
  });

  return navigationItems;
};

export default Docs;
