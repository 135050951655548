import React, { createContext, useContext, useState } from "react";

// Initial state structure
const initialState = {
  title: "Navigation",
  items: [],
};

// Create the context
const SidebarContext = createContext(undefined);

// Create a provider component
export const SidebarProvider = ({ children }) => {
  const [navigationState, setNavigationState] = useState(initialState);

  return (
    <SidebarContext.Provider value={{ navigationState, setNavigationState }}>
      {children}
    </SidebarContext.Provider>
  );
};

// Custom hook to use the SidebarContext
export const useSidebar = () => {
  const context = useContext(SidebarContext);
  if (!context) {
    throw new Error("useSidebar must be used within a SidebarProvider");
  }
  return context;
};

export default SidebarProvider;
