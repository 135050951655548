import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "@cloudscape-design/global-styles/index.css";
import { applyMode, Mode } from "@cloudscape-design/global-styles";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Apply dark mode globally
applyMode(Mode.Dark);

if (!("scrollBehavior" in document.documentElement.style)) {
  import("smoothscroll-polyfill").then((module) => {
    module.polyfill();
  });
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
