import React from "react";
import { SideNavigation } from "@cloudscape-design/components";
import { useSidebar } from "../context/SidebarContext";
import { useLocation, useNavigate } from "react-router-dom";

const Sidebar = () => {
  const { navigationState } = useSidebar(); // Access navigation state
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <SideNavigation
      header={{ text: navigationState.title || "Navigation" }} // Dynamically update the title
      items={[
        {
          type: "section",
          text: navigationState.title || "Navigation", // Fallback to "Navigation"
          items: navigationState.items,
        },
      ]}
      activeHref={location.pathname + location.hash} // Ensure active link highlights correctly
      onFollow={(event) => {
        event.preventDefault();
        const href = event.detail.href;
        if (href) {
          const [path, hash] = href.split("#");
          if (path !== location.pathname) {
            navigate(path, { replace: false }); // Use React Router for path navigation
          }
          if (hash) {
            setTimeout(() => {
              const element = document.getElementById(hash);
              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
              } else {
                console.warn(`Element with ID "${hash}" not found.`);
              }
            }, 0); // Ensure navigation updates before scrolling
          }
        }
      }}
    />
  );
};

export default Sidebar;
